const colors = {
  black: "#000000",
  white: "#FFFFFF",
  grays: {
    "100": "#F7FAFC",
    "200": "#EDF2F7",
    "300": "#E2E8F0",
    "400": "#CBD5E0",
    "500": "#A0AEC0",
    "600": "#718096",
    "700": "#4A5568",
    "800": "#2D3748",
    "900": "#1A202C",
  },
  blues: {
    light: "#0bb7fB",
    dark: "#0D8DD4",
  },
  oranges: {
    light: "#EFAA40",
    dark: "#F36030",
  },
  greens: {
    light: "#8FD045",
    dark: "#48B977",
  },
  reds: {
    "100": "#FFF5F5",
    "200": "#FED7D7",
    "300": "#FEB2B2",
    "400": "#FC8181",
    "500": "#F56565",
    "600": "#E53E3E",
    "700": "#C53030",
    "800": "#9B2C2C",
    "900": "#742A2A",
  },
};

const spacing = {
  xs: ".25rem",
  small: ".5rem",
  medium: "1rem",
  large: "2rem",
  xl: "4rem",
  xxl: "8rem",
  xxxl: "16rem",
};

const fontSizes = {
  xs: "0.75rem",
  small: "0.875rem",
  medium: "1re  m",
  large: "1.125rem",
  xl: "1.25rem",
  xxl: "1.5rem",
  xxxl: "2.25rem",
  xxxxl: "3rem",
};

const borderRadius = {
  base: "6px",
  more: "18px",
};

const dimensions = {
  s1: "1rem",
  s2: "2rem",
  s3: "4rem",
  s4: "8rem",
  s5: "16rem",
};

const simpleBorderColor = colors.grays[300];

const simpleBorder = `1px solid ${simpleBorderColor}`;

const simpleBoxShadow =
  "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)";

const simpleHoverStyles = {
  hoverBackgroundColor: colors.grays[100],
};

export {
  colors,
  spacing,
  fontSizes,
  borderRadius,
  simpleBorder,
  simpleBoxShadow,
  simpleHoverStyles,
  simpleBorderColor,
  dimensions,
};
