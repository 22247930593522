import { Box } from "jsxstyle";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiFacebook, FiLinkedin, FiTwitter } from "react-icons/fi";
import { useLocation, useSearchParam, useTitle } from "react-use";
import "./App.css";
import logo from "./logo.png";
import { borderRadius, fontSizes, spacing } from "./styles";

function App() {
  const qName = useSearchParam("name");
  // const qMessage = useSearchParam("msg");
  const [showScheduler, setShowScheduler] = useState(false);

  const name = qName || "HR";

  useTitle(`Watch this Thank You video to all HR leaders.`);

  const location = useLocation();

  const [copied, setCopied] = useState(false);
  const [firstName, setFirstName] = useState("");
  // const [message, setMessage] = useState("");

  function handleFirstNameChange(event) {
    setFirstName(event.target.value);
    setCopied(false);
  }

  // function handleMessageChange(event) {
  //   setMessage(event.target.value);
  //   setCopied(false);
  // }

  function handleCopy(text, results) {
    setCopied(true);
  }

  function handleSubmit(event) {
    event.preventDefault();
  }

  function handleRequestDemoClicked(event) {
    setShowScheduler(true);
  }

  const params = toQueryString({
    name: firstName,
    // msg: message
  });

  const copyText = `${location.host}?${params}`;
  const shareMessage =
    "Check out ThankYouHR.com. I think you will like this video showing appreciation for all HR leaders.";
  return (
    <Box
      padding="10px"
      display="flex"
      alignItems="center"
      backgroundColor="#3DC1F5"
      flexDirection="column"
    >
      <Box
        color="white"
        // height="100vh"
        paddingBottom={spacing.medium}
        // textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
        mediaQueries={{
          med: "screen and (min-width: 820px)",
        }}
        medWidth="800px"
      >
        <Box
          component="img"
          props={{ src: logo }}
          height="50px"
          margin={spacing.medium}
          position="fixed"
          left="10px"
          zIndex="400"
          smDisplay="none"
          mediaQueries={{
            sm: "screen and (max-width: 640px)",
          }}
        />
        <Box
          fontSize="4.5rem"
          marginBottom={spacing.medium}
          fontFamily="serif"
          textAlign="center"
        >
          Thank you, {name}.
        </Box>
        <Box
          component="div"
          textAlign="center"
          marginBottom={spacing.large}
          fontSize={fontSizes.large}
        >
          We know your job can be lonely, so please watch this video as we show
          our appreciation for all your hard work during these challenging
          times.
        </Box>

        {/* {qMessage && (
        <Box component="div" textAlign="center" marginBottom={spacing.large}>
          {qMessage}
        </Box>
      )} */}

        <Box
          marginBottom={spacing.large}
          borderRadius={borderRadius.more}
          width="100%"
        >
          <img
            alt="Bubbles 1"
            // zIndex="-500"
            src={require("./Bubbles1.png")}
            style={{ position: "absolute", left: "-136px" }}
          />
          <Video />
        </Box>

        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gap={spacing.large}
          marginBottom={spacing.large}
        >
          <img
            alt="Bubbles 2"
            src={require("./Bubbles2.png")}
            style={{ position: "absolute", right: "0" }}
          />
          <a href={`https://twitter.com/intent/tweet?text=${shareMessage}`}>
            <FiTwitter color="white" size="30px" />
          </a>
          {/* <FiInstagram color="white" size="30px" /> */}
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.thankyouhr.com">
            <FiFacebook color="white" size="30px" />{" "}
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=http%3A%2F%2Fwww.thankyouhr.com`}
          >
            <FiLinkedin color="white" size="30px" />
          </a>

          {/* <a
            href={`mailto:myhrfriend@company.com?&subject=&body=https://www.thankyouhr.com 
            
            Check out ThankYouHR.com. I think you will like this video showing appreciation for all HR leaders.`}
          >
            <FiMail color="white" size="30px" />
          </a> */}
        </Box>

        <Box
          component="form"
          width="100%"
          backgroundColor="#FFFEEE"
          border="2px solid #5FBAC2"
          display="flex"
          zIndex={10}
          padding={spacing.medium}
          flexDirection="column"
          boxSizing="border-box"
          borderRadius={borderRadius.more}
          color="black"
          props={{ onSubmit: handleSubmit }}
          marginBottom={spacing.large}
        >
          <Box
            textAlign="left"
            marginBottom={spacing.medium}
            color="#5FBAC2"
            borderRadius={borderRadius.base}
          >
            Share the love with another HR friend.
          </Box>
          <Box
            component="input"
            padding={spacing.small}
            borderRadius={borderRadius.base}
            marginBottom={spacing.medium}
            placeholderColor="#BFD0D0"
            border="2px solid #5FBAC2"
            props={{
              type: "text",
              placeholder: "Enter the recipient's name",
              value: firstName,
              required: true,
              onChange: handleFirstNameChange,
            }}
          />
          {/* <Box
          component="textarea"
          padding={spacing.small}
          borderRadius={borderRadius.base}
          props={{
            placeholder: "Add a message",
            value: message,
            onChange: handleMessageChange,
          }}
          marginBottom={spacing.medium}
          border="2px solid #F6959E"
        /> */}

          <CopyToClipboard text={copyText} onCopy={handleCopy}>
            <input
              type="submit"
              value={copied ? "COPIED!" : "COPY URL"}
              style={{
                fontWeight: "bold",
                backgroundImage: "linear-gradient(#70D8FB, #51B7F5)",
                color: "white",
                width: "100%",
                border: "none",
                borderRadius: borderRadius.base,
                padding: spacing.small,
                hoverCursor: "pointer",
              }}
            />
          </CopyToClipboard>
        </Box>
        <hr
          style={{
            width: "100%",
            // borderTop: "1px solid white",
            marginTop: spacing.medium,
            marginBottom: spacing.medium,
            zIndex: 10,
          }}
        />

        <Box>
          <Box
            fontSize={spacing.large}
            textAlign="center"
            fontWeight="bold"
            marginBottom={spacing.medium}
          >
            Want your HR data in a dashboard?
          </Box>
          <Box textAlign="center" marginBottom={spacing.large}>
            Learn more by scheduling a demo below
          </Box>

          <Box
            textAlign="center"
            borderRadius="12px"
            backgroundColor="#EBAA43"
            padding={spacing.medium}
            marginBottom={spacing.medium}
            zIndex={10}
            fontWeight="bold"
            hoverCursor="pointer"
            props={{ onClick: handleRequestDemoClicked }}
          >
            Request Demo
          </Box>
          <img
            alt="Bubbles 3"
            src={require("./Bubbles3.png")}
            style={{ position: "absolute", left: "-136px" }}
          />
        </Box>
        <Scheduler display={showScheduler} />
        <Box
          marginTop={spacing.xxl}
          component="img"
          zIndex={10}
          props={{ src: logo }}
          height="50px"
          marginBottom={spacing.medium}
        />

        <Box paddingBottom={spacing.xl} zIndex={10}>
          Made with Empathy by{" "}
          <Box
            fontWeight="bold"
            component="a"
            color="white"
            props={{ href: "https://employeecycle.com" }}
            marginBottom={spacing.xl}
          >
            Employee Cycle
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Scheduler({ display }) {
  useEffect(() => {
    if (display) {
      loadScript(
        "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      );
    }
  }, [display]);

  if (!display) {
    return null;
  }

  return (
    <Box width="100%" zIndex={10}>
      <div
        className="meetings-iframe-container"
        data-src="https://app.hubspot.com/meetings/employeecycle/30min?embed=true"
      />
    </Box>
  );
}

function toQueryString(obj) {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
}

export default App;

var loadScript = function (src) {
  var tag = document.createElement("script");
  tag.async = false;
  tag.src = src;
  document.getElementsByTagName("body")[0].appendChild(tag);
};

function Video() {
  const ref = useRef(null);
  return (
    <div
      className="wistia_responsive_padding"
      style={{ padding: "56.25% 0 0 0", position: "relative", zIndex: 10 }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          className="wistia_embed wistia_async_0wh54qd9i6 videoFoam=true"
          style={{ height: "100%", position: "relative", width: "100%" }}
        >
          <div
            className="wistia_swatch"
            ref={ref}
            style={{
              height: "100%",
              left: 0,
              opacity: 0,
              overflow: "hidden",
              position: "absolute",
              top: 0,
              transition: "opacity 200ms",
              width: "100%",
            }}
          >
            <img
              src="https://fast.wistia.com/embed/medias/0wh54qd9i6/swatch"
              style={{
                filter: "blur(5px)",
                height: "100%",
                objectFit: "contain",
                width: "100%",
              }}
              alt=""
              aria-hidden="true"
              onLoad={() => {
                ref.current.style.opacity = 1;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
